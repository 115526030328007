const schema = {
  piiPages: [
    {
      key: "EYES_AND_EARS",
      title: {
        en: 'Eyes and Ears',
        es: 'Ojos y Oídos',
      },
      components: [
        {
          key: 'cc52c0af-6f55-4b62-8bc0-f21ecc404653',
          type: 'YES_NO',
          prompt: {
            en: 'Have you ever lost vision (temporarily or permanently), in either eye?',
            es: '_',
          },
        },
        {
          key: '57fd67d8-5e16-44f5-a94c-6faa12e0b854',
          type: 'YES_NO',
          prompt: {
            en: 'Are you currently experiencing a vision loss in either eye?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'cc52c0af-6f55-4b62-8bc0-f21ecc404653',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'dd4db3e3-7b6f-4302-93d2-81309d8bcd35',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your vision loss.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '57fd67d8-5e16-44f5-a94c-6faa12e0b854',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'c18ca037-bf6b-422b-a041-4d53cc531c9d',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear contact lenses?',
            es: '_',
          },
        },
        {
          key: 'c8900596-2432-46d7-abb5-d38610ab7834',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear glasses?',
            es: '_',
          },
        },
        {
          key: '31865a43-5ae1-455f-b780-d8fe5a6f7476',
          type: 'YES_NO',
          prompt: {
            en: 'Are you color blind?',
            es: '_',
          },
        },
        {
          key: 'c68d98be-e9e2-47d2-8ab0-bae69c0483ca',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have any vision or eye problems that you have not described on this form?',
            es: '_',
          },
        },
        {
          key: '24998a0c-5758-48fa-ae42-a51caf0537b6',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your problems with your vision or eyes.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'c68d98be-e9e2-47d2-8ab0-bae69c0483ca',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '08c4dd00-9758-4331-8646-b8205218ea73',
          type: 'YES_NO',
          prompt: {
            en: 'Have you ever had an ear surgery or injury (including a broken ear drum)?',
            es: '_',
          },
        },
        {
          key: 'c307ff08-96e8-470b-b233-8274da06ef00',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have a permanently broken ear drum?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '08c4dd00-9758-4331-8646-b8205218ea73',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'b6e47537-c776-44b5-be00-a0a1d0a36fab',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your broken ear drum (including any temporary or permanent tubes).',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'c307ff08-96e8-470b-b233-8274da06ef00',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '937eee90-9f9a-4679-bf42-55ae9a56eacd',
          type: 'YES_NO',
          prompt: {
            en: 'Are you currently recovering from an ear surgery or injury?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '08c4dd00-9758-4331-8646-b8205218ea73',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '6e7fe12b-d414-4035-b728-0903cc9fa24e',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your ear surgery or injury.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '937eee90-9f9a-4679-bf42-55ae9a56eacd',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'ecaef1d7-dca5-4ad1-afa5-5e58bf207ce9',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have any difficulty hearing?',
            es: '_',
          },
        },
        {
          key: 'd7cb4246-4272-4181-ae21-03116cc45be9',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear a hearing aid?',
            es: '_',
          },
        },
        {
          key: 'aff6340d-f3cc-46ed-a23e-15cb2abfa54f',
          type: 'YES_NO',
          prompt: {
            en: 'Do you currently have any hearing or ear problems you have not described on this form?',
            es: '_',
          },
        },
        {
          key: '4b4aeb2a-050b-45c1-a296-4ffbbb70fa62',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your problems with your hearing or ears.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'aff6340d-f3cc-46ed-a23e-15cb2abfa54f',
                },
              },
              rhs: 'YES',
            },
          },
        },
      ],
    },
    // {
    //   key: 'PERSONAL_INFORMATION',
    //   title: {
    //     en: 'Personal Information',
    //     es: 'Información Personal',
    //   },
    //   subtitle: {
    //     en: 'Use the fields below to fill in...',
    //     es: 'Usa los campos de abajo para entregar...',
    //   },
    //   components: [
    //     {
    //       key: '50d9423d-7083-4670-8b98-e0b827a072ad',
    //       type: 'INPUT_GROUP',
    //       title: {
    //         en: 'Legal Name',
    //         es: 'Nombre Legal',
    //       },
    //       components: [
    //         {
    //           key: '01612726-f300-43bf-81e8-f7066d9c8615',
    //           type: 'SINGLE_LINE_TEXT',
    //           placeholder: {
    //             en: 'First Name',
    //             es: 'Primer Nombre',
    //           },
    //         },
    //         {
    //           key: '9c4c4f4a-9a15-4308-949f-c551bf690de3',
    //           type: 'SINGLE_LINE_TEXT',
    //           placeholder: {
    //             en: 'Middle Name',
    //             es: 'Segundo Nombre',
    //           },
    //         },
    //         {
    //           key: 'd52915f2-b877-4c7a-8b95-7cbeba455745',
    //           type: 'SINGLE_LINE_TEXT',
    //           placeholder: {
    //             en: 'Last Name',
    //             es: 'Apellido',
    //           },
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  medicalHistoryPages: [
    {
      key: "EYES_AND_EARS",
      title: {
        en: 'Eyes and Ears',
        es: 'Ojos y Oídos',
      },
      components: [
        {
          key: 'cc52c0af-6f55-4b62-8bc0-f21ecc404653',
          type: 'YES_NO',
          prompt: {
            en: 'Have you ever lost vision (temporarily or permanently), in either eye?',
            es: '_',
          },
        },
        {
          key: '57fd67d8-5e16-44f5-a94c-6faa12e0b854',
          type: 'YES_NO',
          prompt: {
            en: 'Are you currently experiencing a vision loss in either eye?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'cc52c0af-6f55-4b62-8bc0-f21ecc404653',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'dd4db3e3-7b6f-4302-93d2-81309d8bcd35',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your vision loss.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '57fd67d8-5e16-44f5-a94c-6faa12e0b854',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'c18ca037-bf6b-422b-a041-4d53cc531c9d',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear contact lenses?',
            es: '_',
          },
        },
        {
          key: 'c8900596-2432-46d7-abb5-d38610ab7834',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear glasses?',
            es: '_',
          },
        },
        {
          key: '31865a43-5ae1-455f-b780-d8fe5a6f7476',
          type: 'YES_NO',
          prompt: {
            en: 'Are you color blind?',
            es: '_',
          },
        },
        {
          key: 'c68d98be-e9e2-47d2-8ab0-bae69c0483ca',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have any vision or eye problems that you have not described on this form?',
            es: '_',
          },
        },
        {
          key: '24998a0c-5758-48fa-ae42-a51caf0537b6',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your problems with your vision or eyes.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'c68d98be-e9e2-47d2-8ab0-bae69c0483ca',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '08c4dd00-9758-4331-8646-b8205218ea73',
          type: 'YES_NO',
          prompt: {
            en: 'Have you ever had an ear surgery or injury (including a broken ear drum)?',
            es: '_',
          },
        },
        {
          key: 'c307ff08-96e8-470b-b233-8274da06ef00',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have a permanently broken ear drum?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '08c4dd00-9758-4331-8646-b8205218ea73',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'b6e47537-c776-44b5-be00-a0a1d0a36fab',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your broken ear drum (including any temporary or permanent tubes).',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'c307ff08-96e8-470b-b233-8274da06ef00',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '937eee90-9f9a-4679-bf42-55ae9a56eacd',
          type: 'YES_NO',
          prompt: {
            en: 'Are you currently recovering from an ear surgery or injury?',
            es: '_',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '08c4dd00-9758-4331-8646-b8205218ea73',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: '6e7fe12b-d414-4035-b728-0903cc9fa24e',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your ear surgery or injury.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: '937eee90-9f9a-4679-bf42-55ae9a56eacd',
                },
              },
              rhs: 'YES',
            },
          },
        },
        {
          key: 'ecaef1d7-dca5-4ad1-afa5-5e58bf207ce9',
          type: 'YES_NO',
          prompt: {
            en: 'Do you have any difficulty hearing?',
            es: '_',
          },
        },
        {
          key: 'd7cb4246-4272-4181-ae21-03116cc45be9',
          type: 'YES_NO',
          prompt: {
            en: 'Do you wear a hearing aid?',
            es: '_',
          },
        },
        {
          key: 'aff6340d-f3cc-46ed-a23e-15cb2abfa54f',
          type: 'YES_NO',
          prompt: {
            en: 'Do you currently have any hearing or ear problems you have not described on this form?',
            es: '_',
          },
        },
        {
          key: '4b4aeb2a-050b-45c1-a296-4ffbbb70fa62',
          type: 'MULTI_LINE_TEXT',
          prompt: {
            en: 'Describe your problems with your hearing or ears.',
            es: '_',
          },
          placeholder: {
            en: 'Description',
            es: 'Descripción',
          },
          include: {
            $equals: {
              lhs: {
                $response: {
                  key: 'aff6340d-f3cc-46ed-a23e-15cb2abfa54f',
                },
              },
              rhs: 'YES',
            },
          },
        },
      ],
    },
  ],
};

export default schema;
