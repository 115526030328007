import { Formik } from 'formik';
import React from 'react';
import { VestForm, useField } from '@vestmed/form-utils';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import schema from './tempSchema';

function MultiLineTextField(props) {
  const [field] = useField(props);

  const allProps = {
    ...field,
    ...props,
  };

  const {
    value,
    ...restAllProps
  } = allProps;

  return (
    <div>
      <FormControl>
        <FormLabel>{props.prompt.en}</FormLabel>
        <TextField
          minRows={3}
          multiline
          type="text"
          value={value || ''}
          variant="filled"
          {...restAllProps}
        />
      </FormControl>
    </div>
  );
}

function YesNoField(props) {
  const [field] = useField(props.name);

  return (
    <div>
      <FormControl>
        <FormLabel>{props.prompt.en}</FormLabel>
        <RadioGroup row {...field} {...props}>
          <FormControlLabel value="YES" control={<Radio />} label="Yes" />
          <FormControlLabel value="NO" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

const COMPONENT_CLASSES = {
  MULTI_LINE_TEXT: MultiLineTextField,
  YES_NO: YesNoField,
};

function App() {
  return (
    <VestForm
      onSubmitCheckpoint={(payload) => {
        alert(JSON.stringify(payload, null, 2));
      }}
      onSubmitFinal={(payload) => {
        alert(JSON.stringify(payload, null, 2));
      }}
      schema={schema}
    >
      {({ currentPage }) => (
        <Formik
          initialValues={{}}
          onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
        >
          {({ handleBlur, handleChange, handleSubmit, values}) => (
            <form onSubmit={handleSubmit}>
              {currentPage.components.map((component) => {
                const ComponentClass = COMPONENT_CLASSES[component.type];

                return (
                  <ComponentClass
                    key={component.key}
                    name={component.key}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    prompt={component.prompt}
                    value={values[component.key] || null}
                  />
                );
              })}
              <Button variant="contained" type="submit">Submit</Button>
            </form>
          )}
        </Formik>
      )}
    </VestForm>
  );
}

export default App;
